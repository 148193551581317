<template>
    <div>    
        <div>
            <b-form-checkbox
            v-if="category.hasOwnProperty('selectAll')"
            v-model="category.selectAll"
            class="mb-1"
            @change="(checked) => toggleAllCategory(checked)">
                <h6 class="mb-0">{{ category.header }}</h6>
            </b-form-checkbox>

            <div v-else class="d-flex align-items-center mb-1">
                <h6 class="mb-0">{{ category.header }}</h6>            
                <InfoIcon
                v-if="category.hasOwnProperty('help')"
                :id="icon_name"
                :help-message="category.help">
                </InfoIcon>
            </div>
        </div>

        <b-input-group  v-if="filter">
            <b-form-input type="text" placeholder="Digite aqui sua busca (ou escolha diretamente na lista abaixo)" v-model="optionsFilter" class="filter-field mb-3"></b-form-input>
        </b-input-group> 

        <b-form-group :name="menu_name" class="rounded-8 px-20 py-3 bg-light-gray max-height-filter-300">
            <div v-for="option in filteredOptions" :key="option.value">
                <b-form-checkbox
                v-model="category.value"
                :value="option.value">
                    {{ option.text }}
                </b-form-checkbox>
                <InfoIcon
                v-if="option.hasOwnProperty('hash')"
                :id="option.hash"
                :help-message="option.help">
                </InfoIcon>
            </div>
            <div v-if="filteredOptions.length==0">
                <p> Nenhuma opção encontrada para a pesquisa. </p>
            </div>
        </b-form-group>    
    </div>
</template>

<script>
 import InfoIcon from './InfoIcon';

 export default {
     components: {InfoIcon},
     props: {
         value: Array,
         category: Object,
         filter: {
             default: false,
             type: Boolean
         }
     },
     data () {
         return {
             optionsFilter: ''
         }
     },
     computed: {
         menu_name () {
             return this.category.id + "_menu"
         },
         icon_name () {
             return this.category.id + "_icon"
         },
         filteredOptions() {             
             if (this.optionsFilter.length>0) {
                 return this.category.options
                            .filter(option => this.matchOption(option.text, this.optionsFilter))
                            .sort(this.compareOptionText)
             }
             else {
                 return this.category.options
             }
         }
     },
     watch: {
         category: {
             deep: true,
             handler () {
                 this.$emit("input", this.category.value)
             }
         },
         value () {
             this.category.value = this.value
         }
     },
     methods: {
         toggleAllCategory(checked) {
             this.category.value = checked ? this.category.options.map(o => o.value) : []
         },
         hashIfHasHelp(element) {
             if ( element.hasOwnProperty('help') ) {
                 element.hash = uuidv4();
             }
             return element;
         },
         matchOption(text, query) {
             // noAccents is defined in app.js
             return new RegExp(noAccents(query),'i').test(noAccents(text));
         },
         compareOptionText(optionA, optionB) {
             // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
             if (optionA.text < optionB.text) {
                 return -1;
             }
             if (optionA.text > optionB.text) {
                 return 1;
             }
             // a must be equal to b
             return 0;
         }
     },     
     created () {
         //category.source must be url string or Array of objects
         if ( Array.isArray(this.category.source) ) {
             this.category.options = this.category.source.map(element => this.hashIfHasHelp(element)).sort(this.compareOptionText);
             this.category.value = this.value ? this.value : []
         }
         else { 
             const promise = axios.get(this.category.source)             
             return promise.then(response => {
                 this.category.options = response.data.map(element => this.hashIfHasHelp(element)).sort(this.compareOptionText);
                 this.category.value = this.value ? this.value : []
             });
         }       
     }
 }
 
</script>
